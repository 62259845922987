<template>
  <public-layout>
      <div class="container mx-auto p-6 content-center md:py-12 lg:px-12 md:w-1/2 xl:w-1/3">
        <div class="flex flex-col px-6 py-8 border border-gray-100 shadow-2xl rounded bg-white lg:px-12">
          <contact-us-form />
        </div>
      </div>
  </public-layout>
</template>
<script>
export default {
  name: 'HelpCenter',
  components: {
    PublicLayout: () => import('@/components/layouts/PublicLayout'),
    ContactUsForm: () => import('@/views/public/help-center/ContactUsForm'),
  }
}
</script>